import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import permissions from "@/store/modules/permissions";
import login from "@/store/modules/login";
import messages from "@/store/modules/messages";
import { ConcurrencyManager } from "axios-concurrency";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { permissions, login, messages },

  state: {
    user: null,
  },

  actions: {
    async start({ state, dispatch }) {
      axios.defaults.xsrfCookieName = "csrftoken";
      axios.defaults.xsrfHeaderName = "X-CSRFToken";
      ConcurrencyManager(axios, 4);

      axios.interceptors.response.use(
        function (response) {
          // Do something with response data
          return response;
        },
        function (error) {
          // Do something with response error
          if (axios.isCancel(error)) {
            // we ignore this
          } else if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            // try to (re)authenticate
            dispatch("setShowLoginDialog", { show: true });
          } else if (error?.response?.status === 500) {
            dispatch("showError", error);
          }
          return Promise.reject(error);
        }
      );

      axios.interceptors.request.use(async (config) => {
        // only let requests marked as privileged unless user data is available
        // this helps to let requests wait until all the required setup is done (user is logged
        // in, some basic data is loaded, etc.)
        if (state.user || config.privileged) {
          return config;
        }
        const watcher = new Promise((resolve) => {
          console.log(`delaying request for ${config.url}`);
          this.watch(
            (state) => state.user,
            (newVal) => {
              if (newVal) resolve();
            }
          );
        });
        try {
          await watcher;
          return config;
        } catch (e) {
          console.error("error waiting for permission to use axios", e);
        }
      });

      dispatch("loadUser");
    },
    async loadUser({ commit, dispatch }) {
      try {
        let resp = await axios.get("/api/user/", { privileged: true });
        commit("setUser", resp.data);
        if (resp.data.anonymous) {
          dispatch("setShowLoginDialog", { show: true });
        }
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 403)
          dispatch("setShowLoginDialog", { show: true });
        return;
      }
    },
    cleanUserData({ commit }) {
      commit("setUser", {});
    },
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
});
