export default {
  state() {
    return {
      showSnackbar: false,
      snackbarText: "",
      snackbarColor: "error",
    };
  },

  actions: {
    showError({ commit }) {
      commit("setSnackbarText", {
        text: "message.backendError",
        color: "error",
      });
    },
    hideSnackbar({ commit }) {
      commit("hideSnackbar");
    },
    showMessage({ commit }, { text, color }) {
      commit("setSnackbarText", { text, color });
    },
  },

  mutations: {
    setSnackbarText(state, { text, color }) {
      state.snackbarText = text;
      state.snackbarColor = color;
      state.showSnackbar = true;
    },
    hideSnackbar(state) {
      state.showSnackbar = false;
    },
  },
};
