import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/es5/util/colors";
import cs from "vuetify/es5/locale/cs";
import en from "vuetify/es5/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { cs, en },
    current: "cs",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.blue,
        secondary: colors.grey,
        accent: colors.orange.lighten2,
        anchor: "#4f88ad",
        ghost: "#e0e0e0",
      },
      dark: {
        primary: colors.blue.darken3,
        anchor: "#4f88ad",
        ghost: "#404040",
      },
    },
  },
});
