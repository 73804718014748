export default {
  getters: {
    hasPermission(state, getters, rootState) {
      function hasPerm(app, model, perm) {
        if (
          !rootState.user ||
          rootState.user.anonymous ||
          !rootState.user.permissions
        ) {
          return false;
        }
        const perms = rootState.user.permissions;
        if (perms && perms[app] && perms[app][model]) {
          return perms[app][model].includes(perm);
        }
        return false;
      }
      return hasPerm;
    },
    permissionsSimplified(state, getters, rootState) {
      let result = new Map();
      if (rootState.user && rootState.user.permissions) {
        Object.values(rootState.user.permissions).forEach((perms) => {
          Object.entries(perms).forEach(([model, perm]) => {
            result.set(model, new Set(perm));
          });
        });
      }
      return result;
    },
    can(state, getters) {
      function _can(action, model) {
        return (
          getters.permissionsSimplified.has(model) &&
          getters.permissionsSimplified.get(model).has(action)
        );
      }
      return _can;
    },
  },
};
