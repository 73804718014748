import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/HomePage.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/AboutPage.vue"),
  },

  {
    path: "/user",
    name: "userPage",
    component: () =>
      import(/* webpackChunkName: "userPage" */ "@/views/UserPage.vue"),
  },

  {
    path: "/person/:personId",
    name: "personDetail",
    component: () =>
      import(
        /* webpackChunkName: "personDetail" */ "@/views/PersonDetailPage.vue"
      ),
    props: (route) => ({
      personId: Number.parseInt(route.params.personId, 10),
    }),
  },

  {
    path: "/organization/:organizationId",
    name: "organizationDetail",
    component: () =>
      import(
        /* webpackChunkName: "organizationDetail" */ "@/views/OrgDetailPage.vue"
      ),
    props: (route) => ({
      organizationId: Number.parseInt(route.params.organizationId, 10),
    }),
  },
  {
    path: "/reset-password/",
    name: "reset-password",
    component: () => import("@/views/PasswordResetPage.vue"),
    meta: {
      outsideNormalLayout: true,
      invitation: false,
    },
  },
  {
    path: "/accept-invitation/",
    name: "accept-invitation",
    component: () => import("@/views/PasswordResetPage.vue"),
    meta: {
      outsideNormalLayout: true,
      invitation: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
