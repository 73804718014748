import validateEmail from "@/lib/email-validation";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t("field.required"),
        min: (v) => v.length >= 8 || this.$t("field.minPwdLength"),
        email: (v) =>
          !v || !!validateEmail(v) || this.$t("field.emailRequired"),
        integer: (v) =>
          !v || /^\d+$/.test(v) || this.$t("field.integerRequired"),
        phone: (v) =>
          !v || /^\+?\d[ \d]+\d$/.test(v) || this.$t("field.phoneRequired"),
        reg_number: (v) =>
          !v || /^\d{8}$/.test(v) || this.$t("field.regNumber"),
      },
    };
  },
};
