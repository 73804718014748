<template>
  <router-view v-if="$route.meta.outsideNormalLayout"> </router-view>
  <v-app v-else>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title class="flex-sm-shrink-0">
        <router-link
          :to="{ name: 'Home' }"
          tag="div"
          class="d-flex align-center cursor-link"
        >
          <v-icon large class="mr-2">mdi-contacts-outline</v-icon>
          <span class="text-h4 text--white">{{ $t("appName") }}</span>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom class="ml-auto">
        <template #activator="{ on }">
          <v-btn icon v-on="on" class="mr-lg-0" :to="{ name: 'userPage' }">
            <v-icon large>mdi-account</v-icon>
          </v-btn>
        </template>
        {{ $t("label.userSettings") }}
      </v-tooltip>
    </v-app-bar>

    <LoginDialog v-if="showLoginDialog" />

    <v-main v-else class="mb-8">
      <v-alert type="warning" class="ma-6" v-if="user && user.is_cloud_user">
        You are logged in as super-superuser and thus see all realms mixed into
        one. You should not use this account for any frontend work outside
        admin.
      </v-alert>
      <router-view />
      <v-snackbar v-model="snackbarShow" :color="snackbarColor" timeout="8000">
        {{ $t(snackbarText) }}
        <template #action="{ attrs }">
          <v-btn dark text @click="hideSnackbar" v-bind="attrs">{{
            $t("button.close")
          }}</v-btn>
        </template>
      </v-snackbar>
    </v-main>

    <v-footer absolute app>
      <span>&copy; {{ new Date().getFullYear() }}</span>
      <v-spacer />
      <img src="@/assets/ntk-96.svg" alt="Logo NTK" height="72" />
      <v-spacer />
      <v-switch :label="$t('label.darkMode')" v-model="darkMode" />
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LoginDialog from "@/components/account/LoginDialog";
//import VueI18n from "vue-i18n";

export default {
  name: "App",
  components: { LoginDialog },
  data: () => ({
    darkMode: false,
  }),

  computed: {
    ...mapState({
      showLoginDialog: (state) => state.login.showLoginDialog,
      user: "user",
      snackbarText: (state) => state.messages.snackbarText,
      snackbarColor: (state) => state.messages.snackbarColor,
      showSnackbar: (state) => state.messages.showSnackbar,
    }),
    snackbarShow: {
      get() {
        return this.showSnackbar;
      },
      set(newValue) {
        if (newValue === false) this.hideSnackbar();
      },
    },
  },

  methods: {
    ...mapActions({
      vuexStart: "start",
      hideSnackbar: "hideSnackbar",
    }),
  },

  mounted() {
    this.vuexStart();
  },

  watch: {
    darkMode() {
      this.$vuetify.theme.dark = this.darkMode;
    },
  },
};
</script>

<style lang="scss">
.subdued {
  color: #999999;
  font-size: 87.5%;
}

a.subdued {
  //color: #4f88ad !important;
  text-decoration: none;
  font-size: 100%;
}

.nolink {
  text-decoration: none;
}

ul.no-bullet {
  list-style: none;
}

div.icons {
  border-radius: 7px;
}

:hover > div.icons {
  background-color: #aaaaaa;

  .ghost--text {
    color: #f3f3f3 !important;
  }
}

.role {
  font-weight: 300;
  font-size: 92.5%;
}

.cursor-link {
  cursor: pointer;
}

table.overview {
  tr {
    th {
      padding-right: 1rem;
      text-align: left;
      vertical-align: top;
      padding-bottom: 0.375rem;

      &::after {
        content: ":";
        font-weight: normal;
      }
    }

    td {
      vertical-align: top;
    }
  }
}
</style>
